<template>
  <div>
    <div
      class="body-2 black--text"
      >
      <span
        @click="expanded = !expanded"
        :class="expanded ? '' : 'description-box'"
        ref="description"
        >
        {{ post.body }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      expanded: false,
      clamp: false
    }
  },

  props: {
    post: {
      required: true,
      type: Object
    }
  },

  mounted () {
    this.clamp = this.$refs.description.scrollHeight > this.$refs.description.clientHeight
  }
}
</script>

<style scoped>
>>> .v-input__slot {
  padding: 0 !important;
}
</style>
