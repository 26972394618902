<template>
  <div
    class="d-flex flex-row justify-space-between align-center"
    v-if="currentUser"
    >
    <v-snackbar
      right
      outlined
      :timeout="-1"
      v-model="alert.active"
      color="error"
      >
      <div
        class="font-weight-medium"
        >
        {{ alert.message }}
      </div>

      <template v-slot:action="{ attrs }">
        <v-btn
          icon
          v-bind="attrs"
          color="error"
          @click="alert.active = false"
          >
          <v-icon>
            mdi-close
          </v-icon>
        </v-btn>
      </template>
    </v-snackbar>

    <v-dialog
      width="600"
      v-model="dialog"
      >
      <v-card>
        <v-card-title>
          Me gusta
        </v-card-title>
        <v-card-text>
          <profiles-list
            :results="likes"
            ></profiles-list>
        </v-card-text>
      </v-card>
    </v-dialog>
    <div
      class="d-flex flex-row align-center"
      >
      <v-btn
        depressed
        small
        class="mr-2 rounded-lg px-1"
        @click="like"
        color="white"
        v-hammer:touchhold="openDialog"
        >
        <v-icon
          :color="liked ? 'red' : ''"
          >
          {{ liked ? 'mdi-heart' : 'mdi-heart-outline' }}
        </v-icon>

        <div
          class="ml-1"
          :class="liked ? 'red--text' : ''"
          >
          {{ post.likedBy.length }}
        </div>
      </v-btn>

      <v-btn
        depressed
        small
        class="mr-2 rounded-lg px-1"
        color="white"
        @click="$emit('openModal')"
        >
        <v-icon>
          mdi-message-outline
        </v-icon>

        <div
          class="ml-1"
          >
          {{ post.commentIds.length }}
        </div>
      </v-btn>

      <share
        :postId="post.id"
        ></share>
    </div>

    <div
      class="text-right"
      >
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="secondary"
            icon
            v-bind="attrs"
            v-on="on"
            >
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>
        <v-list>
          <edit
            :post="post"
            @reload="$emit('reload')"
            ></edit>
          <delete
            :id="post.id"
            :post="post"
            @reload="$emit('reload')"
            ></delete>
          <report
            :post="post"
            @reload="reported"
            ></report>
        </v-list>
      </v-menu>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { LikePost } from '@/graphql/mutations/posts'
import { Profiles } from '@/graphql/queries/profiles'

const Delete = () => import('@/components/posts/Delete')
const Edit = () => import('@/components/posts/Edit')
const Report = () => import('@/components/posts/Report')

const Product = () => import('@/components/products/Product')
const Share = () => import('./sharing/Share')
const ProfilesList = () => import('@/components/profiles/List')

export default {
  data: () => ({
    dialog: false,
    likes: true,
    alert: {
      active: false,
      message: null
    }
  }),

  props: {
    post: {
      type: Object,
      required: true
    }
  },

  methods: {
    like () {
      this.$apollo.mutate({
        mutation: LikePost,
        variables: {
          input: {
            id: this.post.id
          }
        }
      }).then( res => {
        this.$emit('reload')
      })
    },

    openDialog () {
      this.$apollo.query({
        query: Profiles,
        variables: {
          ids: this.post.likedBy
        }
      }).then ( res => {
        this.likes = res.data.profiles
        this.$nextTick( () => {
          this.dialog = true
        })
      })
    },

    reported () {
      this.alert.message = "Reporte enviado con éxito. ¡Muchas gracias por contribuir en crear una mejor comunidad!"
      this.alert.active = true
      this.$emit('report')
    },
  },

  computed: {
    ...mapGetters(['currentUser']),

    liked () {
      return this.post.likedBy.includes(this.currentUser.selectedProfile.id)
    }
  },

  components: { Product, Share, ProfilesList, Delete, Edit, Report }
}
</script>
