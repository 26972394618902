<template>
  <div
    id="post"
    v-if="post"
    >
    <v-dialog
      v-if="modal"
      v-model="modal"
      width="fit-content"
      :fullscreen="$vuetify.breakpoint.mobile"
      scrollable
      >
      <floating
        @close="modal = false"
        @reload="fetchPost"
        :key="counter"
        :idx="idx"
        :ids="post.commentIds"
        :postId="post.id"
        ></floating>
    </v-dialog>

    <v-card
      class="fill-width rounded-lg"
      >
      <div
        style="position: relative"
        >
        <div
          v-if="reported && hide"
          class="text-center pa-3 white rounded-lg elevation-6 d-flex align-center"
          style="position: absolute; bottom: 50%; left: 50%; z-index: 3; width: fit-content; transform: translate(-50%, 50%); gap: 8px"
          >
          <div
            >
            <v-icon
              color="error"
              large
              >
              mdi-information
            </v-icon>
          </div>

          <div
            class="body-1"
            style="width: 200px"
            >
            Reportaste esta publicación.
          </div>

          <div>
            <v-btn
              outlined
              small
              @click="hide = false"
              >
              Ver
            </v-btn>
          </div>
        </div>
        <div
          :style="reported && hide ? 'filter: blur(3px)' : ''"
          >
          <v-card-title
            @click.stop="$router.push({name: 'profile', params: {slug: post.profile.slug}})"
            style="cursor: pointer"
            >
            <v-avatar 
              size="36"
              >
              <v-img 
                v-if="post.profile.picture"
                :src="url + post.profile.picture.url"
                ></v-img>
              <v-icon
                v-else
                >
                {{ post.profile.profilableType == 'Store' ? 'mdi-storefront' : 'mdi-account-circle' }}
              </v-icon>
            </v-avatar>
            <div
              class="ml-2 d-flex flex-column justify-center"
              >
              <div
                class="text-body-2 font-weight-medium"
                >
                {{ post.profile.name }}
              </div>
              <div
                class="caption"
                v-if="!post.pictures.length && post.postType != 'standard'"
                >
                {{ $t('posts.types.' + post.postType) }}
              </div>
            </div>

            <v-spacer></v-spacer>

            <div
              class="caption"
              >
              Hace {{ transformTime(post.elapsed) }}
            </div>
          </v-card-title>

          <v-card-subtitle
            class="py-3"
            >
            <description
              :post="post"
              ></description>
          </v-card-subtitle>

          <div
            v-if="post.pictures.length"
            >
            <v-carousel 
              ref="image"
              :height="height"
              :show-arrows="false"
              hide-delimiter-background
              :hide-delimiters="post.pictures.length == 1"
              >
              <v-carousel-item
                v-for="(picture, i) in post.pictures"
                :class="picture.filter"
                :key="picture.id"
                :src="url + picture.url"
                >
                <div
                  style="width: 100%; position: absolute; top: 0"
                  class="d-flex flex-row"
                  >
                  <v-card
                    v-if="post.postType != 'standard'"
                    style="width: fit-content"
                    class="mx-auto rounded-t-0"
                    >
                    <v-card-text
                      class="text-center caption px-3 py-1 black--text"
                      >
                      {{ $t('posts.types.' + post.postType) }}
                    </v-card-text>
                  </v-card>
                </div>
              </v-carousel-item>
            </v-carousel>
          </div>

          <product
            v-if="post.product"
            :post="post"
            ></product>
          <Store
            v-if="!post.product && post.store"
            :store="post.store"
            ></Store>
        </div>
      </div>
      
      <v-card-text
        class="py-2"
        >
        <actions
          :post="post"
          @openModal="modal = true"
          @reload="fetchPost"
          @report="reported = true"
          ></actions>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { Post } from '@/graphql/queries/posts'
import Actions from '@/components/posts/Actions'
import Description from '@/components/posts/Description'

const Chip = () => import('@/components/posts/Chip')
const Floating = () => import('@/components/posts/Floating')
const Product = () => import('@/components/posts/Product')
const Store = () => import('@/components/posts/Store')

export default {
  data () {
    return {
      counter: 0,
      modal: false,
      post: null,
      height: '100vw',
      reported: false,
      hide: true
    }
  },

  props: {
    idx: {
      required: true,
      type: Number | String
    },
    postId: {
      required: true,
      type: Number | String
    },
    share: {
      required: false,
      type: Boolean
    },
    tile: {
      required: false,
      type: Boolean,
      default: false,
    },
    outlined: {
      required: false,
      type: Boolean,
      default: false,
    },
  },

  components: {
    Actions, Description, Floating, Chip, Product, Store
  },

  watch: {
    currentUser () {
      this.reported = this.currentUser.reportedPostsIds.includes(this.postId)
    }
  },

  created () {
    this.fetchPost()
    if (this.currentUser) this.reported = this.currentUser.reportedPostsIds.includes(this.postId)
  },

  methods: {
    fetchPost () {
      this.$apollo.query({
        query: Post,
        variables: {
          id: this.postId
        },
        fetchPolicy: 'no-cache'
      }).then( res => {
        this.post = res.data.post
        this.counter ++
        
        this.$nextTick( () => {
          if (this.$refs.image) {
            this.height = this.$refs.image.$el.offsetWidth
          }
        })
      })
    },

    transformTime (seconds) {
      if (seconds < 60) {
        if (seconds == 1) return seconds + ' segundo'
        return seconds + ' segundos'
      } else if (seconds >= 60) {
        var min = Math.floor(seconds / 60)
        if (min == 1) return min + ' minuto'
        if (min < 60) return min + ' minutos'

        var hour = Math.floor(min / 60)
        if (hour == 1) return hour + ' hora'
        if (hour < 24) return hour + ' horas'

        var days = Math.floor(hour / 24)
        if (days == 1) return days + ' día'
        return days + ' días'
      }
    },
  },

  computed: {
    ...mapGetters(['currentUser', 'url']),
  }
}
</script>
